import { Helmet } from 'react-helmet-async';
// @mui
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { postData } from '../utils/api';
import { ToastError, ToastSuccess, generateHash, getTimeStamp } from '../utils/helper';
import OtpModel from './OtpModel';

const validationSchema = yup.object().shape({
  username: yup
    .string()
    .required('Username is required')
    .matches(/^[A-Za-z0-9]+$/, 'special symbols are not allowed')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  // email: yup
  //   .string()
  //   .required('Email is required')
  //   .matches(/^[^\s].*/, 'starting spaced is not allowed')
  //   .email('Invalid email format'),
  first_name: yup
    .string()
    .required(' First Name is required')
    .matches(/^[a-zA-Z]+$/, 'Only alphabets are allowed')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  last_name: yup
    .string()
    .required(' Last Name is required')
    .matches(/^[a-zA-Z]+$/, 'Only alphabets are allowed')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),

  // dob: yup
  //   .string()
  //   .nullable()
  //   .required('Date of Birth is required')
  //   .max(new Date(), 'Date of Birth cannot be in the future')
  //   .test('is-adult', 'You must be at least 18 years old', (value) => {
  //     if (!value) return false; // Invalid date
  //     const today = new Date();
  //     const dob = new Date(value);
  //     const age = today.getFullYear() - dob.getFullYear();
  //     if (age > 18) return true;
  //     if (age === 18) {
  //       // Check month and day for 18th birthday
  //       if (today.getMonth() > dob.getMonth()) return true;
  //       if (today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate()) return true;
  //     }
  //     return false;
  //   }),
  phone: yup
    .string()
    .required('Mobile Number is required')
    .min(7, 'Please enter minimum 7 numbers')
    .max(12, 'Please enter maximum 12 numbers'),
    

  // password: yup.lazy((value) => {
  //   if (value) {
  //     return yup.string().min(6, 'Password must be at least 6 characters');
  //     // .matches(/^[^\s].*/, 'starting spaced is not allowed')
  //     // .matches(
  //     //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
  //     //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
  //     // );
  //   }
  //   return yup.string().required('Password is required');
  // }),
  password: yup.string().required('Password is required')
  .min(8, 'Password must be at least 8 characters')
  .matches(/^[^\s].*/, 'starting spaced is not allowed')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
  )
});

export default function CreatePlayer() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [date, setDate] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [otpVal, setOtpVal] = useState('');
  const [disable, setDisable] = useState(false);
  const { user } = useContext(UserContext);
  const [error, setError] = useState('');
  const [phone, setPhone] = useState('');
  const [OtpBtn, setOtpBtn] = useState(false);
  const [userId, setUserId] = useState([]);
  const [seconds, setSeconds] = useState(5 * 60);
  const [isClicked, setIsClicked] = useState(false);

  const handleClose = async () => {
    toast.dismiss();
    const data = {
      user_token: user.token,
      data: {
        user_id: userId[0].user_id,
      },
    };
    try {
      const cancleRequestRespone = await postData(`/${user.type}/user/cancel`, data);

      if (cancleRequestRespone.status_code === 200) {
        setOpen(false);
        setError('');
        setOtpVal(false);
        setTimeout(() => {
          setSeconds(5 * 60);
          setDisable(false);
        }, 500);
      }
      ToastSuccess(cancleRequestRespone.message);
    } catch (error) {
      console.log('otp error:>> ', error);
    }
  };

  const onSubmit = async (playerVal) => {
    setIsClicked(true);
    toast.dismiss();
    const data = {
      user_token: user.token,
      data: {
        shop_id: user.shop.length > 0 ? user.shop[0].id : 0,
        first_name: playerVal.first_name,
        last_name: playerVal.last_name,
        username: playerVal.username,
        password: playerVal.password,
        // email: playerVal.email,
        country_code: playerVal.country_code,
        // dob: playerVal.dob,
        phone: playerVal.phone,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
      const profileData = await postData(`/${user.type}/user/create`, data);
      if (profileData.status_code === 200) {
        setUserId(profileData.data);
        ToastSuccess(profileData.message);
        setTimeout(() => {
          setOpen(true);
          setIsClicked(false);
        }, 700);
      }
    } catch (error) {
      ToastError(error.response.data.message);
      setTimeout(() => {
        setIsClicked(false);
      }, 1000);
    }
  };

  const onSubmitOtp = async () => {
    toast.dismiss();
    try {
      if (otpVal !== '') {
        if (otpVal.length !== 6) {
          setError('OTP should be 6 digits long.');
          return;
        }

        if (!/^\d+$/.test(otpVal)) {
          setError('OTP should contain only numbers.');
          return;
        }
      } else {
        setError('Please Enter OTP');
        return;
      }
      setOtpBtn(true);
      const data = {
        user_token: user.token,
        data: {
          user_id: userId[0].user_id,
          otp: otpVal,
          date_time: getTimeStamp(),
          
        },
      };
      const hashSignature = generateHash(data.data);
      data.data.hash = hashSignature;
      const profileData = await postData(`/${user.type}/verify/otp`, data);
      if (profileData.status_code === 200) {
        ToastSuccess(profileData.message);
        setTimeout(() => {
          reset();
          setDate('');
          setPhone('');
          setDisable(false);
          setOtpBtn(false);
          setOpen(false);
          setOtpVal('');
        }, 600);
      }
    } catch (error) {
      console.log('otp error:>> ', error);
      ToastError(error.response.data.message);
      setTimeout(() => {
        setOtpBtn(false);
      }, 500);
    }
  };

  const handleOtpChange = (newValue) => {
    setOtpVal(newValue);
    setError('');
  };

  const handlePhoneChange = (value, data) => {
    setPhone(value);
    const country_code = data.dialCode;
    const phone_no = value.slice(data.dialCode.length);
    setValue('phone', phone_no, true);
    setValue('country_code', country_code);
  };

  const onResendOtp = async () => {
    setDisable(true);
    setOtpVal('');
    const data = {
      user_token: user.token,
      data: {
        user_id: userId[0].user_id,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
      const profileData = await postData(`/${user.type}/resent/otp`, data);
      toast.success(profileData.message, {
        position: 'top-center',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        theme: 'colored',
      });
      setTimeout(() => {
        setDisable(false);
      }, 300000);
      setSeconds(5 * 60);
    } catch (error) {
      console.log('resend :>> ', error);
    }
  };

  const cancle = () => {
    reset();
    setDate('');
    setPhone(`+${MY_GLOBAL_CONST.country_code}`);
  };

  const today = new Date();
  // const todaydate = today.getDate();
  // const month = today.getMonth() + 1;
  // const year = today.getFullYear() - 18;

  // const onchangeDate = async (event) => {
  //   if (event !== null) {
  //     const start_date = new Date(event);
  //     const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
  //     await trigger('dob');
  //     setValue('dob', utcstartDate);
  //     setDate(dayjs(formatDate(event, 'datepicker')));
  //   }
  // };
  return (
    <>
      <Helmet>
        <title> Create Player | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="md">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon
              icon="ph:user-fill"
              style={{
                fontSize: '20px',
                backgroundColor: '#a5a7a9',
                padding: '2px',
                borderRadius: '3px',
                alignSelf: 'center',
                marginRight: '3px',
              }}
            />
            <Typography mt={1} variant="h4" gutterBottom>
              Create Player
            </Typography>
          </Box>
        </Stack>

        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack flexDirection="row" className="flexWrap">
                <Box className="col-100 mrgy_10" width="50%" margin={2}>
                  <TextField
                    name="first_name"
                    {...register('first_name')}
                    fullWidth
                    variant="outlined"
                    label="First Name"
                    placeholder="First Name"
                    disableUnderline
                  />
                  <Typography variant="body2" color="error">
                    {errors.first_name?.message}
                  </Typography>
                </Box>
                <Box width="50%" className="col-100 mrgy_10" margin={2}>
                  <TextField
                    name="last_name"
                    {...register('last_name')}
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    placeholder=" Last Name"
                    disableUnderline
                  />
                  <Typography variant="body2" color="error">
                    {errors.last_name?.message}
                  </Typography>
                </Box>
                {/* <Box margin={2} width="50%">
                  <TextField
                    name="email"
                    fullWidth
                    {...register('email')}
                    variant="outlined"
                    label="Email"
                    placeholder="Email"
                    disableUnderline
                  />
                  <Typography variant="body2" color="error">
                    {errors.email?.message}
                  </Typography>
                </Box> */}
              </Stack>
              {/* row 2 */}

              <Stack flexDirection="row" className="flexWrap">
                <Box width="50%" className="col-100 mrgy_10" margin={2}>
                  <TextField
                    name="username"
                    fullWidth
                    variant="outlined"
                    label="Username"
                    {...register('username')}
                    placeholder="Username"
                    disableUnderline
                  />
                  <Typography variant="body2" color="error">
                    {errors.username?.message}
                  </Typography>
                </Box>
                <Box padding={2} width="50%" className="col-100 mrgy_10 pd_0">
                  <PhoneInput
                    disableDropdown={MY_GLOBAL_CONST.is_disable_country_dropdown}
                    color="black"
                    name="phone"
                    variant="outlined"
                    placeholder="Phone No"
                    size="large"
                    country={`${MY_GLOBAL_CONST.country_short_code}`}
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                  <Typography variant="body2" color="error">
                    {errors.phone?.message}
                  </Typography>
                </Box>
              </Stack>

              {/* row 3 */}

              {/* <Stack flexDirection="row"> */}
              {/* <Box padding={2} width="50%">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="dob"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          sx={{ width: '100%' }}
                          maxDate={dayjs(`${month}-${todaydate}-${year}`)}
                          value={date}
                          // {...register('dob')}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                            onchangeDate(newValue);
                          }}
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                          label="Date Of Birth"
                          renderInput={(params) => <TextField {...params} size="medium" marginBottom="20px" />}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Typography variant="body2" color="error">
                    {errors.dob && <span className="error">{errors.dob.message}</span>}
                  </Typography>
                </Box> */}
              {/* </Stack> */}

              <Stack direction="row">
                <Box padding={2} width="50%" className="col-100 mrgy_10 pd_0">
                  <TextField
                    placeholder="Enter Password"
                    {...register('password')}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    fullWidth
                    variant="outlined"
                    label="Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Typography variant="body2" color="error">
                    {errors.password?.message}
                  </Typography>
                </Box>
              </Stack>

              {/* button row */}

              <Stack flexDirection="row" marginLeft="auto" margin={2} justifyContent="right">
                <Button type="reset" variant="text" sx={{ marginRight: '10px' }} onClick={cancle}>
                  Cancel
                </Button>

                <Button type="submit" disabled={isClicked} variant="contained">
                  Create User
                </Button>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Container>
      <OtpModel
        open={open}
        handleClose={handleClose}
        OtpBtn={OtpBtn}
        onSubmitOtp={onSubmitOtp}
        handleOtpChange={handleOtpChange}
        otpVal={otpVal}
        onResendOtp={onResendOtp}
        setSeconds={setSeconds}
        seconds={seconds}
        disable={disable}
        error={error}
      />
    </>
  );
}
