import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as yup from 'yup';

import { Icon } from '@iconify/react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { UserContext } from '../contexts/UserContext';
import { aviatorPostData, fetchDataWithAuth, postData } from '../utils/api';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { ToastError, ToastSuccess, formatDate, generateHash, getTimeStamp } from '../utils/helper';

const schema = yup.object().shape({
  username: yup.string().required('Username is required').min(3).max(20),
  first_name: yup
    .string()
    .required(' Name is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed')
    .matches(/^[A-Za-z]+$/, 'Only alphabetic characters are allowed')
    .min(3)
    .max(25),
  last_name: yup
    .string()
    .required('Surname is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed')
    .matches(/^[A-Za-z]+$/, 'Only alphabetic characters are allowed')
    .min(3)
    .max(25),
  phone: yup
    .string()
    .required('Phone Number is required')
    .min(7, 'Please enter minimum 7 numbers')
    .max(12, 'Please enter maximum 12 numbers'),
  email: yup
    .string()
    .required('Email is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed')
    .email()
    .max(25),
  address: yup
    .string()
    .required('Address is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed')
    .min(3),
  vat_number: yup.lazy((value) => {
    if (value) {
      return yup
        .string()
        .matches(/^[^\s].*/, 'starting spaced is not allowed')
        .min(3, 'Please enter minimum 3 numbers')
        .max(12, 'Please enter maximum 12 numbers');
    }
    return yup.string();
  }),
});

const changePasswordschema = yup.object().shape({
  old_password: yup
    .string()
    .required('Please Enter your password')
    .matches(/^[^\s].*/, 'starting spaced is not allowed')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  new_password: yup
    .string()
    .required('Please Enter your New password')
    .matches(/^[^\s].*/, 'starting spaced is not allowed')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
});

const Profile = ({ userType }) => {
  const [selectedTab, setSelectedTab] = useState('profile');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [date, setDate] = useState(null);
  const [disableEdit, setDisableEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [passwordLoading, setPasswordLoading] = useState(false);

  const [type, setType] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit: SubmitChangePassword,
    register: registerChangePassword,
    reset: changePasswordReset,
    formState: { errors: errorChangePassword },
  } = useForm({ resolver: yupResolver(changePasswordschema) });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const onSubmit = async (adminVal) => {
    setDisableEdit(true);
    setLoading(true);
    toast.dismiss();
    const data = {
      user_token: user.token,
      data: {
        vat_number: adminVal.vat_number,
        first_name: adminVal.first_name,
        last_name: adminVal.last_name,
        address: adminVal.address,
        username: adminVal.username,
        email: adminVal.email,
        phone: adminVal.phone,
        country_code: adminVal.country_code,
        dob: adminVal.dob,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
      const profileData = await postData(`/${user.type}/profile/update`, data);
      ToastSuccess(profileData.message);
      setDisableEdit(false);
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
      setDisableEdit(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdminProfile();
    setType(userType === 'aviator' || userType === 'aviator-operator');
  }, []);
  const getAdminProfile = async () => {
    setLoading(true);
    const data1 = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/${user.type}/profile/detail`, data1);
    const profileData = response.data[0];
    setValue('first_name', profileData.first_name);
    setValue('last_name', profileData.last_name);
    setValue('address', profileData.address);
    setValue('email', profileData.email);
    setValue('username', profileData.username);
    setValue('vat_number', profileData.vat_number);
    setValue('country_code', profileData.country_code);
    setValue('phone', `${profileData.phone}`);
    setValue('dob', profileData.dob);
    setPhoneNumber(profileData.phone);
    setCountryCode(profileData.country_code);
    setDate(dayjs(formatDate(profileData.dob, 'datepicker')));
    setLoading(false);
  };

  const handlePhoneChange = (value, data) => {
    const country_code = data.dialCode;
    const phone_no = value.slice(data.dialCode.length);
    setValue('phone', phone_no, true);
    setValue('country_code', country_code);
  };

  const min_date = new Date();
  const minDate = dayjs(min_date);

  const onSubmitChangeP = async (passwordData) => {
    setDisable(true);
    try {
      setPasswordLoading(true);
      const data = {
        user_token: user.token,
        data: {
          old_password: passwordData.old_password,
          new_password: passwordData.new_password,
          date_time: getTimeStamp(),
        },
      };
      const hashSignature = generateHash(data.data);
      data.data.hash = hashSignature;
      const profileData = await postData(`/${user.type}/change/password`, data);
      if (type) {
        const body = {
          data: {
            oldPassword: passwordData.old_password,
            newPassword: passwordData.new_password,
          },
        };
        await aviatorPostData('/user/change-password', body);
      }
      ToastSuccess(profileData.message);
      changePasswordReset();
      setDisable(false);
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false);
    } finally {
      setPasswordLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {' '}
          Admin {selectedTab} | {`${MY_GLOBAL_CONST.main_title}`} Admin{' '}
        </title>
      </Helmet>
      <Container className="profilePage">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="icomoon-free:profile" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Profile
            </Typography>
          </Box>
        </Stack>
        <Card className="transtionCard">
          <CardContent>
            <TabContext value={selectedTab}>
              <Box>
                <TabList value={selectedTab} onChange={handleTabChange}>
                  <Tab label="Profile" value="profile" />
                  <Tab label="Change Password" value="changePassword" />
                </TabList>
              </Box>
              <TabPanel value="profile">
                {loading ? (
                  <Stack display="flex" flexDirection="row" justifyContent="center">
                    <CircularProgress size={35} />
                  </Stack>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="row" sx={{ mt: 3 }} className="temptranstaionStack">
                      <Box padding={2} width="33%" className="width_100">
                        <TextField
                          type="text"
                          fullWidth
                          disabled
                          placeholder="Enter Username"
                          {...register('username')}
                          InputLabelProps={{
                            shrink: true,
                            classes: { root: 'input-label' },
                          }}
                          InputProps={{ classes: { root: 'input-field' } }}
                          name="username"
                          label="Enter Username"
                        />
                        <Typography variant="body2" color="error">
                          {errors.username?.message}
                        </Typography>
                      </Box>
                      <Box padding={2} width="33%" className="width_100">
                        <TextField
                          fullWidth
                          placeholder="Enter Name"
                          {...register('first_name')}
                          InputLabelProps={{
                            shrink: true,
                            classes: { root: 'input-label' },
                          }}
                          InputProps={{ classes: { root: 'input-field' } }}
                          name="first_name"
                          label="Enter Name"
                        />
                        <Typography variant="body2" color="error">
                          {errors.first_name?.message}
                        </Typography>
                      </Box>
                      <Box padding={2} width="33%" className="width_100">
                        <TextField
                          type="text"
                          fullWidth
                          placeholder="Enter Surname"
                          label="Enter Surname"
                          inputStyle={{
                            background: 'none',
                            border: 'none',
                            fontWeight: 'normal',
                            width: '100%',
                          }}
                          {...register('last_name')}
                          InputLabelProps={{
                            shrink: true,
                            classes: { root: 'input-label' },
                          }}
                          InputProps={{ classes: { root: 'input-field' } }}
                          name="last_name"
                        />
                        <Typography variant="body2" color="error">
                          {errors.last_name?.message}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack direction="row" flexDirection="row" className="profileStack">
                      <Box padding={2} width="33%" className="width_100 telPd">
                        <PhoneInput
                          disableDropdown={MY_GLOBAL_CONST.is_disable_country_dropdown}
                          color="black"
                          inputProps={{
                            name: 'phone',
                            autoFocus: true,
                            ref: { ...register('phone') },
                          }}
                          style={{
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                          }}
                          inputStyle={{
                            background: 'none',
                            border: 'none',
                            fontWeight: 'normal',
                            width: '100%',
                          }}
                          variant="outlined"
                          placeholder="Phone No"
                          size="large"
                          country={`${MY_GLOBAL_CONST.country_short_code}`}
                          onChange={handlePhoneChange}
                          value={`${countryCode}${phoneNumber}`}
                        />
                        <Typography variant="body2" color="error">
                          {errors.phone?.message}
                        </Typography>
                      </Box>
                      <Box padding={2} width="33%" className="width_100 profileBxPd">
                        <TextField
                          fullWidth
                          label="Enter Email"
                          placeholder="Enter Email"
                          {...register('email')}
                          name="email"
                          InputLabelProps={{
                            shrink: true,
                            classes: { root: 'input-label' },
                          }}
                          InputProps={{ classes: { root: 'input-field' } }}
                        />
                        <Typography variant="body2" color="error">
                          {errors.email?.message}
                        </Typography>
                      </Box>

                      <Box padding={2} width="33%" className="width_100 profileBxPd">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: '100%' }}
                            maxDate={minDate}
                            value={date}
                            format="DD-MM-YYYY"
                            disabled
                            onChange={(e) => {
                              setDate(formatDate(e, 'datepicker'));
                            }}
                            slotProps={{
                              textField: {
                                error: false,
                              },
                            }}
                            label="Date Of Birth"
                            InputLabelProps={{
                              shrink: true,
                              classes: { root: 'input-label' },
                            }}
                            InputProps={{ classes: { root: 'input-field' } }}
                          />
                        </LocalizationProvider>
                        {errors.dob?.message}
                      </Box>
                    </Stack>

                    {!type && (
                      <Stack direction="row" className="profileStack">
                        <Box padding={2} width="33%" className="width_100 profileBxPd">
                          <TextField
                            fullWidth
                            placeholder="Enter Vat Number"
                            {...register('vat_number')}
                            name="vat_number"
                            label="Vat Number"
                            InputLabelProps={{
                              shrink: true,
                              classes: { root: 'input-label' },
                            }}
                            InputProps={{ classes: { root: 'input-field' } }}
                          />
                          <Typography variant="body2" color="error">
                            {errors.vat_number?.message}
                          </Typography>
                        </Box>

                        <Box padding={2} width="66%" className="width_100 profileBxPd">
                          <TextField
                            fullWidth
                            multiline
                            rows={4}
                            placeholder="Enter Address"
                            {...register('address')}
                            InputLabelProps={{
                              shrink: true,
                              classes: { root: 'input-label' },
                            }}
                            InputProps={{ classes: { root: 'input-field' } }}
                            name="address"
                            label="Address"
                          />
                          <Typography variant="body2" color="error">
                            {errors.address?.message}
                          </Typography>
                        </Box>
                      </Stack>
                    )}

                    <Box padding={2} width="100%" sx={{ display: 'flex', justifyContent: 'right' }}>
                      <Button
                        color="secondary"
                        size="large"
                        sx={{ padding: '0 40px' }}
                        variant="contained"
                        disabled={disableEdit}
                        type="submit"
                      >
                        Edit
                      </Button>
                    </Box>
                  </form>
                )}
              </TabPanel>
              <TabPanel value="changePassword">
                {passwordLoading ? (
                  <Stack display="flex" flexDirection="row" justifyContent="center">
                    <CircularProgress size={35} />
                  </Stack>
                ) : (
                  <Stack
                    component="form"
                    onSubmit={SubmitChangePassword(onSubmitChangeP)}
                    direction="row"
                    sx={{ mt: 3 }}
                    className="profileStack profileColRevrce"
                  >
                    <Box padding={2} width="50%" className="width_100 ">
                      <TextField
                        placeholder="Enter Password"
                        {...registerChangePassword('old_password')}
                        type={showNewPassword ? 'text' : 'password'}
                        name="old_password"
                        fullWidth
                        variant="outlined"
                        label="Old Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                              >
                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Typography variant="body2" color="error">
                        {errorChangePassword.old_password?.message}
                      </Typography>
                      <Button
                        type="submit"
                        size="large"
                        color="secondary"
                        variant="contained"
                        className="mrgTop16"
                        disableElevation
                        sx={{ mt: 4 }}
                        disabled={disable}
                      >
                        Save changes
                      </Button>
                    </Box>
                    <Box padding={2} width="50%" className="width_100">
                      <TextField
                        placeholder="Enter New Password"
                        {...registerChangePassword('new_password')}
                        type={showPassword ? 'text' : 'password'}
                        name="new_password"
                        fullWidth
                        variant="outlined"
                        label="New  Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Typography variant="body2" color="error">
                        {errorChangePassword.new_password?.message}
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Profile;
