import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, CardContent, CircularProgress, Container, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { generateHash, getTimeStamp, ToastError, ToastSuccess } from '../../utils/helper';

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  address: yup
    .string()
    .required('Address is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  contact_number: yup
    .string()
    .matches(/^[^\s].*/, 'starting spaced is not allowed')
    // .matches(/^(\+)?[0-9]{8,}$/, 'Contact number must be a valid phone number')
    .required('Contact number is required')
    .min(7, 'Please enter minimum 7 numbers')
    .max(12, 'Please enter maximum 12 numbers'),
});

const ShopForm = ({ action }) => {
  const Redirect = useNavigate();
  const params = useParams();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [disable, setDisable] = useState(false);
  const [loading,setLoading] = useState(false)

  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const getShop = async () => {
      setLoading(true)
      if (params.id) {
        const data = {
          user_token: user.token,
          data: {},
        };
        const response = await fetchDataWithAuth(`/admin/shop/get/${params.id}`, data);
        const shopData = response.data[0];
        setValue('name', shopData.name);
        setValue('address', shopData.address);
        setValue('contact_number', shopData.contact_number);
        setValue('country_code', shopData.country_code);
        setPhoneNumber(shopData.contact_number);
        setCountryCode(shopData.country_code);
      }
      setLoading(false)
    };
    getShop();
  }, [params, setValue]);

  const onSubmit = async (shopData) => {
    // Perform form submission logic here
    setLoading(true)
    setDisable(true)
    toast.dismiss();
    let data = null;
    let RoleData = null;
    try {
      if (params.id) {
        data = {
          user_token: user.token,
          data: {
            id: params.id,
            name: shopData.name,
            contact_number: shopData.contact_number,
            country_code: shopData.country_code,
            date_time: getTimeStamp(),
            address: shopData.address,
          },
        };
        const hashSignature = generateHash(data.data);
        data.data.hash = hashSignature;
        RoleData = await postData('/admin/shop/update', data);
      } else {
        data = {
          user_token: user.token,
          data: {
            name: shopData.name,
            date_time: getTimeStamp(),
            contact_number: shopData.contact_number,
            country_code: shopData.country_code,
            address: shopData.address,
          },
        };
        const hashSignature = generateHash(data.data);
        data.data.hash = hashSignature;
        RoleData = await postData('/admin/shop/add', data);
      }
      ToastSuccess(RoleData.message);
      setTimeout(() => {
        toast.dismiss();
        Redirect('/admin/shop');
      }, 800);
      setDisable(false)
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false)
    }
    setLoading(false)
  };

  const handleOnChange = (value, data) => {
    const country_code = data.dialCode;
    const phone_no = value.slice(data.dialCode.length);
    setValue('contact_number', phone_no, true);
    setValue('country_code', country_code);
  };

  return (
    <>
      <Helmet>
        <title> Shop {action} | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="md">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Shop {action}
          </Typography>
        </Stack>
        <Card>
          <CardContent>
           {loading ?  
         <Stack display="flex" flexDirection="row" justifyContent="center">
         <CircularProgress size={35} />
       </Stack> : <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <TextField
                    label="Name"
                    {...register('name')}
                    // error={!!errors.name}
                    // helperText={errors.name?.message}
                    placeholder='Name'
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                    // sx={{ textAlign: 'center', margin: '5px' }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.name?.message}
                  </Typography>
                </Box>
                <Box padding={2} width="50%">
                  {/* <TextField
                    label="Contact Number"
                    {...register('contact_number')}
                    // error={!!errors.contact_number}
                    // helperText={errors.contact_number?.message}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                    // sx={{ textAlign: 'center', margin: '5px' }}
                  /> */}
                  <PhoneInput
                    disableDropdown={MY_GLOBAL_CONST.is_disable_country_dropdown}
                    color="black"
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true,
                      ref: { ...register('contact_number') },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                    value={`${countryCode}${phoneNumber}`}
                    InputLabelProps={{
                      height: '56px',
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    variant="outlined"
                    placeholder="Phone No"
                    fullWidth
                    country={`${MY_GLOBAL_CONST.country_short_code}`}
                    onChange={handleOnChange}
                  />
                  <Typography variant="body2" color="error">
                    {errors.contact_number?.message}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <TextField
                    multiline
                    rows={4}
                    label="Address"
                    placeholder='Address'
                    {...register('address')}
                    // error={!!errors.address}
                    // helperText={errors.address?.message}
                    fullWidth

                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                    // sx={{ textAlign: 'center', margin: '5px' }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.address?.message}
                  </Typography>
                </Box>
              </Stack>
              <Box padding={2}>
                <Button type="submit" variant="contained" color="primary" size="large" disabled={disable}>
                  {action} Shop
                </Button>
              </Box>
            </form>}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default ShopForm;
